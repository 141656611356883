
              @import "@/assets/css/variables.scss";
            

















































































@import '@/assets/css/components/promotion/blueHeadTable.scss';

/deep/ .el-table.head-gutter td {
  border-bottom: 1px solid #ebeef5;
}

.el-table.head-gutter /deep/ th .cell {
  padding: 14px 12px;
}

.table-heade-cell {
  min-height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
}
